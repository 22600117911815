import React, { useState, useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
// redux toolkit libraries
import { useSelector, useDispatch } from "react-redux";
import { connect } from "react-redux";
// image
import logo from "../ExecConnect Logo Transparent.png";
import excoEdge from "../assets/images/exco-edge.svg";
import ibm from "../ibm-logo.png";
import excoedge from "../exco-edge.svg";
import { BsTwitterX } from "react-icons/bs";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
// mui components
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { green } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@mui/material/Chip";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@mui/material/Divider";
import TextField from "@material-ui/core/TextField";
import { CUSTOMER_LOGO_URL } from "../utils/config";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GoogleIcon from "@mui/icons-material/Google";
import TwitterIcon from "@mui/icons-material/Twitter";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// formik
import { useFormik, Formik, validateYupSchema } from "formik";
// exco library
import { MyTextInput } from "@excoleadershipui/pionweblib";
//compoments
import { FormSubmitButton } from "../components";
import Notifier from "./common/Notifier";
import ErrorNotifier from "./common/ErrorNotifier";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import homeImageOne from "../../src/assets/images/image-home3.png";
import homeImageTwo from "../../src/assets/images/home-image-two.png";
import {
  verifyEmail,
  login,
  resetMessage,
  resetErrMessage,
  rolesErrMessageFunc,
  rolesErrMessageEmptyFunc,
  customerLoggedInFunc,
  customerLoggedInEmptyFunc,
  localStoreRoleFunc,
  getOauthProviders,
  isCheckingRoles,
  isLoggedIn,
  isLoggedVerifyRoles,
  isLoggedMicrsoft,
  isLoggedGoogle,
} from "../features/auth/authSlice";
import { BorderColor, ElevatorSharp } from "@mui/icons-material";
import { Text } from "@progress/kendo-drawing";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  textField: {
    // "& .MuiInputLabel-root":{                    //if the below css has any parent then the syntax will be like this
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00527d",
    },
    "& .MuiInputLabel-asterisk": {
      color: "black !important",
    },
    // "& .Mui-focused":{
    //  color:'#00527d !important',
    // },
  },
}));

function ExcoLogin() {
  const [showEmail, setShowEmail] = useState(true);
  const [showPass, setShowPass] = useState(false);
  const [showRole, setShowRole] = useState(false);
  const [roleData, setRoleData] = useState("");
  const {
    loading,
    loginToken,
    rq,
    message,
    errMessage,
    loggedIn,
    rolesErrMessage,
    customerCustomMessage,
    allRoleData,
    rolesApiData,
    logginRolesData,
    oauthProvidersData,
  } = useSelector((state) => ({
    ...state.auth,
  }));

  console.log(message);
  console.log(errMessage);

  let navigate = useNavigate();
  let location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const error = searchParams.get("error"); // Example: Get 'error' param

  const classes = useStyles();
  // const style

  const GreenCheckbox = withStyles({
    root: {
      color: "black",
      "&$checked": {
        color: "black",
      },
    },
    checked: {},
  })((props) => <Checkbox labelStyle={{ color: "black" }} {...props} />);

  const dispatch = useDispatch();

  const handlePass = () => {
    setShowPass(true);
    setShowEmail(false);
  };

  const handleRoleNavigate = () => {
    setShowPass(false);
    setShowEmail(false);
    setShowRole(true);
  };

  const handleBack = () => {
    setShowPass(false);
    setShowEmail(true);
  };

  useEffect(async () => {
    dispatch(isLoggedIn());
    dispatch(isLoggedVerifyRoles());
    dispatch(getOauthProviders());
  }, []);

  const handleRoleSubmit = () => {
    if (roleData) {
      dispatch(localStoreRoleFunc(roleData));
      dispatch(customerLoggedInFunc());
      dispatch(isLoggedIn());
      setTimeout(() => {
        (roleData === "CLIENT" && navigate("/exco/dashboard")) ||
          (roleData === "MANAGER" && navigate("/exco/manager/dashboard")) ||
          (roleData === "HR" && navigate("/exco/hr/dashboard")) ||
          (roleData === "CHR" && navigate("/exco/chr/dashboard"));
        dispatch(customerLoggedInEmptyFunc());
      }, 2000);
    } else {
      dispatch(rolesErrMessageFunc());
      setTimeout(() => {
        dispatch(rolesErrMessageEmptyFunc());
      }, 2000);
    }
  };

  const OnroleChange = (e) => {
    setRoleData(e.target.value);
    localStorage.setItem("state", e.target.value);
    localStorage.getItem("state");
  };

  useEffect(() => {
    return (
      (loggedIn === true &&
        logginRolesData === "CLIENT" &&
        navigate("/exco/dashboard")) ||
      (loggedIn === true &&
        logginRolesData === "MANAGER" &&
        navigate("/exco/manager/dashboard")) ||
      (loggedIn === true &&
        logginRolesData === "HR" &&
        navigate("/exco/hr/dashboard")) ||
      (loggedIn === true &&
        logginRolesData === "CHR" &&
        navigate("/exco/chr/dashboard"))
    );
  }, [logginRolesData, loggedIn]);

  useEffect(() => {
    if (error === "E_EXCO_CUSTOMER_CLIENT_NOT_FOUND") {
      navigate("/E_EXCO_CUSTOMER_CLIENT_NOT_FOUND"); // Redirect to a different page
    }
  }, [error, navigate]);

  const useStylesTwo = makeStyles((theme) => ({
    bullet: {
      display: "inline-block",
      margin: theme.spacing(1), // Adjust the spacing between the bullet and text
      transform: "scale(0.8)", // Adjust the size of the bullet if needed
      // Customize the bullet style (e.g., change color)
      // You can add more styles like fontSize, fontWeight, etc. here as well
      color: "white",
    },

    textTypo: {
      color: "#FFFFFF",
      fontSize: "0.8rem",
      fontWeight: 200,
    },
  }));

  const useStylesThree = makeStyles((theme) => ({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      width: "300px",
      backgroundColor: "red", // Replace with the desired background color
    },

    avatarContainer: {
      position: "relative",
    },
    avatarBackground: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: "#e0e0e0", // Set your desired background color
      borderRadius: "50%", // Make it circular
    },
    avatarImage: {
      width: "100%",
      height: "auto",
      zIndex: 1, // Ensure the image is above the background layer
    },
  }));

  const LoginThirdParty = () => {
    const handleMicrosoftIconClick = (id) => {
      dispatch(isLoggedMicrsoft({ id: id })).then((response) => {
        if (response?.payload?.data) {
          window.location.href = response?.payload?.data;
          // window.location.href = response?.payload?.data;
        }
      });
    };
    const handleGoogleIconClick = (id) => {
      console.log(id);
      dispatch(isLoggedGoogle({ id: id })).then((response) => {
        if (response?.payload?.data) {
          window.location.href = response?.payload?.data;
          // window.location.href = response?.payload?.data;
        }
      });
    };

    return (
      <Box textAlign="center" sx={{ marginTop: 2 }}>
        <Stack
          direction="column" // ✅ Change to column for vertical stacking
          spacing={2} // ✅ Add spacing between buttons
          justifyContent="center"
          alignItems="center" // ✅ Center the buttons horizontally
        >
          {oauthProvidersData?.length > 0 &&
            oauthProvidersData.map((data) => {
              const isGoogle = data?.providerName === "google";
              const isMicrosoft = data?.providerName === "entra";
              if (!isGoogle && !isMicrosoft) return null; // Skip unknown providers

              return (
                <Button
                  key={data?.id} // ✅ Unique key
                  variant="outlined"
                  fullWidth
                  style={{
                    textTransform: "none",
                    maxWidth: 300, // ✅ Match button width
                    justifyContent: "flex-start", // Align icon and text

                    color: "#00527d",
                    cursor: "pointer",
                  }}
                  startIcon={
                    isGoogle ? (
                      <GoogleIcon
                        sx={{ color: "#00527d", cursor: "pointer" }}
                      />
                    ) : (
                      <MicrosoftIcon
                        sx={{ color: "#00527d", cursor: "pointer" }}
                      />
                    )
                  }
                  onClick={() =>
                    isGoogle
                      ? handleGoogleIconClick(data?.id)
                      : handleMicrosoftIconClick(data?.id)
                  }
                >
                  {data?.displayName}
                </Button>
              );
            })}
        </Stack>
      </Box>
    );
  };

  function SocialMedia(props) {
    return (
      <Box textAlign="center" sx={{ marginTop: 2 }}>
        <Typography variant="body2">Stay Social</Typography>
        <Stack
          direction="row"
          spacing={1}
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
        >
          <a
            style={{ textDecoration: "none", color: "#00527d" }}
            href="https://www.linkedin.com/company/excoleadership/"
          >
            <LinkedInIcon textAlign="center" />
          </a>
          <a
            style={{ textDecoration: "none", color: "#00527d" }}
            href="https://twitter.com/ExCoLeadership"
          >
            <h3
              // data-toggle="tooltip"
              // data-placement="top"
              // onClick={this.handleAssignedView}
              style={{
                marginTop: "-0.01rem",
                cursor: "pointer",
                fontSize: "1.2rem",
              }}
            >
              {" "}
              <BsTwitterX />{" "}
            </h3>
          </a>
        </Stack>
      </Box>
    );
  }

  const BulletTypography = ({ text }) => {
    const classes = useStylesTwo();

    return (
      <Typography>
        {text.split("  ").map((word, index) => (
          <React.Fragment key={index}>
            <span className={classes.bullet}>&#8226;</span>
            <span className={classes.textTypo}>{word}</span>{" "}
          </React.Fragment>
        ))}
      </Typography>
    );
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={0.1}>
          <Stack sx={{ padding: "2rem" }}>
            <img
              width="170"
              height="30"
              src={logo}
              className="App-logo"
              alt="logo"
            />
          </Stack>
        </Grid>
        <Grid
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
          container
          item
          xs={12}
          sm={12}
          md={5.9}
        >
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (showEmail) {
                return dispatch(verifyEmail({ email: values.email })).then(
                  (res) => {
                    if (res?.payload?.data?.ok) {
                      setTimeout(() => {
                        dispatch(resetMessage());
                        handlePass();
                      }, 200);
                    }
                    if (res?.payload?.error) {
                      setTimeout(() => {
                        dispatch(resetErrMessage());
                      }, 2000);
                    }
                    if (res?.payload?.warn) {
                      setTimeout(() => {
                        dispatch(resetErrMessage());
                      }, 2000);
                    }
                  }
                );
              }
              if (showPass) {
                return dispatch(
                  login({
                    email: values.email,
                    password: values.password,
                    token: loginToken,
                    rq: rq,
                    remember: true,
                  })
                ).then((res) => {
                  console.log(res);

                  if (res?.payload?.data?.ok) {
                    if (
                      res?.payload?.data?.data?.getExcoCustomerUserRoles
                        ?.length == 1
                    ) {
                      dispatch(isLoggedIn());
                      setTimeout(() => {
                        dispatch(resetMessage());
                        navigate(
                          (res?.payload?.data?.data?.getExcoCustomerUserRoles[0]
                            ?.role === "CLIENT" &&
                            "exco/dashboard") ||
                            (res?.payload?.data?.data
                              ?.getExcoCustomerUserRoles[0]?.role ===
                              "MANAGER" &&
                              "exco/manager/dashboard") ||
                            (res?.payload?.data?.data
                              ?.getExcoCustomerUserRoles[0]?.role === "HR" &&
                              "exco/hr/dashboard") ||
                            (res?.payload?.data?.data
                              ?.getExcoCustomerUserRoles[0]?.role === "CHR" &&
                              "exco/chr/dashboard")
                        );
                      }, 200);
                    } else if (
                      res?.payload?.data?.data?.getExcoCustomerUserRoles
                        ?.length > 1
                    ) {
                      dispatch(resetMessage());
                      handleRoleNavigate();
                    }
                  }
                  if (res?.payload?.error) {
                    setTimeout(() => {
                      dispatch(resetErrMessage());
                    }, 3000);
                  }
                });
              }
            }}
          >
            {({ errors, touched, handleChange, handleSubmit, values }) =>
              loading ? (
                <Box>
                  <CircularProgress />
                </Box>
              ) : (
                <Stack>
                  <Stack sx={{ alignSelf: "flex-start" }}>
                    {showPass && (
                      <IconButton
                        sx={{}}
                        onClick={() => {
                          setShowPass(false);
                          setShowRole(false);
                          setShowEmail(true);
                          values.email = "";
                        }}
                      >
                        <ArrowBackIcon color="black" />
                      </IconButton>
                    )}
                  </Stack>
                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{
                      mt: 1,
                      display: "flex",
                      fontFamily: "arial",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {/* <Stack>
                      <img
                        width="170"
                        height="30"
                        src={logo}
                        className="App-logo"
                        alt="logo"
                      />
                    </Stack> */}
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: "700",
                        marginTop: "1rem",
                      }}
                      variant="h5"
                      gutterBottom
                      component="div"
                      href="exco"
                    >
                      Sign in
                    </Typography>

                    <Stack
                      sx={{
                        background: "#FFFFFF",
                        height: "4rem",
                        width: "20rem",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {showEmail && (
                        <TextField
                          className={classes.textField}
                          label="Email Address"
                          variant="outlined"
                          type="email"
                          name="email"
                          required={true}
                          margin="normal"
                          id="email"
                          onChange={handleChange("email")}
                          autoFocus
                          // InputLabelProps={{
                          //   sx: { "& .Mui-focused": { color: "#00527d !important" } },
                          // }}
                        />
                      )}

                      {showPass && (
                        <TextField
                          className={classes.textField}
                          sx={{ background: "#FFFFFF" }}
                          variant="outlined"
                          label="Password"
                          type="password"
                          name="password"
                          required={true}
                          margin="normal"
                          id="password"
                          onChange={handleChange("password")}
                          autoFocus
                        />
                      )}

                      {showRole && (
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Roles
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="country"
                            value={roleData}
                            label="ROLES"
                            onChange={OnroleChange}
                          >
                            {allRoleData.length > 0 &&
                              allRoleData.map((data) => {
                                return (
                                  <MenuItem key={data.id} value={data.role}>
                                    {data.role}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      )}
                    </Stack>
                    {showPass && (
                      <Link
                        className="link"
                        to="/forgot-password"
                        variant="body2"
                      >
                        Forgot password?
                      </Link>
                    )}

                    {showPass ||
                      (showEmail && (
                        <Stack>
                          <FormControlLabel
                            label={<Typography>Remember Me</Typography>}
                            control={<GreenCheckbox name="checkedG" />}
                          />
                        </Stack>
                      ))}
                    {showEmail && (
                      <FormSubmitButton
                        type="button"
                        sx={{ width: "8rem", marginTop: "1rem" }}
                        variant="contained"
                        className={classes.btnSubmit}
                        onClick={handleSubmit}
                      >
                        NEXT
                      </FormSubmitButton>
                    )}
                    {showPass && (
                      <FormSubmitButton
                        type="submit"
                        variant="contained"
                        className={classes.btnSubmit}
                        sx={{ marginTop: "1rem", width: "10rem" }}
                        onClick={handleSubmit}
                      >
                        Sign In
                      </FormSubmitButton>
                    )}

                    {showRole && (
                      <FormSubmitButton
                        type="submit"
                        variant="contained"
                        className={classes.btnSubmit}
                        sx={{ marginTop: "1rem", width: "10rem" }}
                        onClick={handleRoleSubmit}
                      >
                        Submit
                      </FormSubmitButton>
                    )}
                    <Stack sx={{ marginTop: "1rem" }}>
                      <Typography sx={{ textAlign: "center" }}>Or</Typography>
                      <LoginThirdParty />
                    </Stack>

                    <Stack sx={{ marginTop: "1rem" }}>
                      <SocialMedia />
                    </Stack>
                  </Box>
                </Stack>
              )
            }
          </Formik>
        </Grid>

        <Grid container className="footer-page" item xs={12} sm={12} md={6}>
          <Grid container item xs={12} sm={12} md={12} justifyContent="end">
            <Grid sx={{ margin: "1rem" }} item>
              <img
                width="100"
                height="40"
                style={{ objectFit: "contain" }}
                src={CUSTOMER_LOGO_URL}
                className="App-logo"
                alt="logo"
              />
            </Grid>
          </Grid>

          <Grid justifyContent="center" alignItems="center" container item>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "90%",

                "& > :not(style)": {
                  m: 1,
                },
              }}
            >
              <Grid container item xs={12} sm={12} md={12}>
                <Grid item md={10.6}>
                  <img
                    width="150"
                    height="50"
                    src={excoEdge}
                    className="App-logo"
                    alt="logo"
                  />
                </Grid>

                <Grid item md={10.6}>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "500",
                      color: "whitesmoke",
                    }}
                    variant="body1"
                    gutterBottom
                  >
                    We bring our experience as operators to every engagement
                  </Typography>
                </Grid>
              </Grid>

              <Paper
                sx={{
                  borderRadius: "1rem",
                  background: "#cfe1dc",
                  minHeight: 200,
                }}
                variant="outlined"
              >
                <Grid m="2rem" container>
                  <Grid item sm={12} xs={12} md={2}>
                    <Avatar
                      sx={{
                        background:
                          "linear-gradient(45deg, rgba(0, 81, 122, 1) 0%, rgba(114, 178, 120, 1) 65%)",
                        width: 56,
                        height: 56,
                      }}
                      className={useStylesThree.avatar}
                    >
                      <img
                        width={30}
                        height={30}
                        // style={{ objectFit: "cover", borderRadius: "50%" }}
                        src={homeImageOne}
                        alt="AvatarOne"
                        className={useStylesThree.avatarImage}
                      />
                    </Avatar>
                    {/* <Avatar
                      alt="Remy Sharp"
                      src={homeImageOne}
                      sx={{
                        width: 56,
                        height: 56,
                        background:
                          "linear-gradient(45deg, rgba(0, 81, 122, 1) 0%, rgba(114, 178, 120, 1) 65%)",
                      }}
                    /> */}
                  </Grid>

                  <Grid item sm={12} xs={12} md={6}>
                    <Typography
                      sx={{ fontSize: "1.5rem", fontWeight: 700 }}
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      Latest Insights
                    </Typography>
                    <Typography
                      sx={{ fontSize: "1rem", fontWeight: 500 }}
                      variant="body1"
                      gutterBottom
                    >
                      As thought leaders across multiple industries, we actively
                      drive conversations that generate insights, resulting in
                      pragmatic outcomes.
                    </Typography>
                    {/* <Typography
                      sx={{
                        fontSize: "1rem",
                        marginTop: "-0.25rem",
                        fontWeight: 500,
                      }}
                      variant="body1"
                      gutterBottom
                    >
                      
                    </Typography> */}
                  </Grid>

                  <Grid alignSelf="end" container item sm={12} xs={12} md={4}>
                    <Grid item>
                      <Chip
                        sx={{
                          fontWeight: "bold",
                          width: "8rem",
                          fontSize: "0.8rem",
                          textAlign: "end",
                          color: "#ffff",
                          cursor: "pointer",
                          background: "black",
                          "&:hover": {
                            background:
                              "linear-gradient(45deg, rgba(0, 81, 122, 1) 0%, rgba(114, 178, 120, 1) 65%)",
                          },
                        }}
                        label="Read More"
                        component="a"
                        href="https://www.excoleadership.com/articles/"
                        variant="outlined"
                        // clickable
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                sx={{
                  borderRadius: "1rem",
                  background: "#cfe1dc",
                  minHeight: 200,
                }}
                variant="outlined"
              >
                <Grid m="2rem" container>
                  <Grid item sm={12} xs={12} md={2}>
                    {/* <div className={classes.root}> */}
                    <Avatar
                      sx={{
                        background:
                          "linear-gradient(45deg, rgba(0, 81, 122, 1) 0%, rgba(114, 178, 120, 1) 65%)",
                        width: 56,
                        height: 56,
                      }}
                      // className={useStylesThree.avatar}
                    >
                      <img
                        width={30}
                        height={30}
                        src={homeImageTwo}
                        alt="AvatarTwo"
                        // className={useStylesThree.avatarImage}
                      />
                    </Avatar>
                    {/* </div> */}
                    {/* <Avatar
                      alt="Remy Sharp"
                      src={homeImageTwo}
                      sx={{
                        width: 56,
                        height: 56,
                        bgcolor:
                          "linear-gradient(45deg, rgba(0, 81, 122, 1) 0%, rgba(114, 178, 120, 1) 65%)",
                      }}
                    /> */}
                  </Grid>
                  <Grid item sm={12} xs={12} md={6}>
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        fontWeight: 700,
                      }}
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      Services
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: 500,
                        overflowWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                      variant="div"
                      gutterBottom
                    >
                      We partner with organizations across a wide range of
                      industries, bringing unparalleled industry experience to
                      Executive Coaching and Mentoring.
                    </Typography>
                  </Grid>

                  <Grid alignSelf="end" container item sm={12} xs={12} md={4}>
                    <Grid item>
                      <Chip
                        sx={{
                          width: "8rem",
                          fontWeight: "bold",
                          fontSize: "0.8rem",
                          textAlign: "end",
                          cursor: "pointer",
                          color: "#ffff",
                          background: "black",
                          "&:hover": {
                            background:
                              "linear-gradient(45deg, rgba(0, 81, 122, 1) 0%, rgba(114, 178, 120, 1) 65%)",
                          },
                        }}
                        label="Learn More"
                        component="a"
                        href="https://www.excoleadership.com/senior-executive-development/"
                        variant="outlined"
                        // clickable
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Stack>
                <Grid
                  justifyContent="end"
                  alignItems="end"
                  sx={{ marginTop: "0.5rem" }}
                  container
                  item
                  sm={12}
                  md={12}
                  xs={12}
                >
                  <Grid textAlign="end" item sm={12} md={4} xs={12}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.125rem",
                        fontWeight: "700",
                      }}
                      variant="h6"
                      component="div"
                    >
                      WHO WE SERVE
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  // justifyContent="end"
                  // alignItems="end"
                  container
                  item
                  sx={{ marginTop: "1rem" }}
                  sm={12}
                  md={12}
                  xs={12}
                >
                  <Grid textAlign="end" item sm={12} md={4} xs={12}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.125rem",
                        fontWeight: "500",
                      }}
                      variant="h6"
                      gutterBottom
                      // component="div"
                    >
                      CEOs
                    </Typography>
                  </Grid>
                  <Grid textAlign="end" item sm={12} md={4} xs={12}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.125rem",
                        fontWeight: "500",
                      }}
                      variant="h6"
                      gutterBottom
                      // component="div"
                    >
                      SENIOR LEADERS
                    </Typography>
                  </Grid>

                  <Grid textAlign="end" item sm={12} md={4} xs={12}>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.125rem",
                        fontWeight: "500",
                      }}
                      variant="h6"
                      gutterBottom
                      // component="div"
                    >
                      ORGANIZATIONS
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  justifyContent="end"
                  alignItems="end"
                  container
                  item
                  sm={12}
                  md={12}
                  xs={12}
                >
                  <Grid textAlign="end" item sm={12} md={12} xs={12}>
                    <BulletTypography text="Public  Private Companies   Private Equality  Venture Capital   Non-Profits" />
                  </Grid>
                </Grid>
              </Stack>

              {/* <Stack sx={{ width: "100%" }}>
                <Grid container spacing={2} item sm={12} md={12} xs={12}></Grid>
              </Stack> */}
              {/* </Grid> */}

              {/* <Stack
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "center",
                    flexDirection: "column",
                    fontFamily: "arial",
                  }}
                 >
                

                  <Stack
                    sx={{ display: "flex", flexDirection: "row", mt: "0.5rem" }}
                  >
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.125rem",
                        fontWeight: "500",
                      }}
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      CEOs
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.125rem",
                        fontWeight: "500",
                        marginLeft: "2rem",
                      }}
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      SENIOR LEADERS
                    </Typography>
                    <Typography
                      sx={{
                        color: "#FFFFFF",
                        fontSize: "1.125rem",
                        fontWeight: "500",
                        marginLeft: "2rem",
                      }}
                      variant="h6"
                      gutterBottom
                      component="div"
                    >
                      ORGANIZATION
                    </Typography>
                  </Stack>

                 
                </Stack> */}
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {errMessage && <ErrorNotifier message={errMessage} />}
      {rolesErrMessage && <ErrorNotifier message={rolesErrMessage} />}
      {message && <Notifier message={message} />}
      {customerCustomMessage && <Notifier message={customerCustomMessage} />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  VerifyEmail: state.verifyEmail,
  LoginAccount: state.loginAccount,
});

export default connect(mapStateToProps)(ExcoLogin);
