import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  ListItemButton,
  IconButton,
  Stack,
  Button,
  ListItemIcon,
} from "@mui/material";
import { MuiTooltip } from "../components";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ErrorIcon from "@mui/icons-material/Error";
import { getAllRoles } from "../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { getToogleData } from "../features/ratings/ratingSlice";
import { getToggleUserData } from "../features/auth/authSlice";
import { getClientIsApprpved } from "../features/auth/authSlice";

export const TopBar = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [clientName, setClientName] = useState(
    JSON.parse(localStorage.getItem("clientid"))
  );

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { rolesApiData, clientApprovedData, toggleUserData } = useSelector((state) => ({
    ...state?.auth,
  }));

  const {

    clientToggleData,
  } = useSelector((state) => ({ ...state.clientRating }));

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const useStyles = makeStyles((theme) => ({
    MuiLinks: {
      textDecoration: "none",
    },
    listButton: {
      "&.MuiListItemButton-root": {
        backgroundColor: "#fff",
        // ...theme.typography.body2,
        // padding: theme.spacing(1),
        // textAlign: "center !important",
        textDecoration: "none",
        color: "black",
        border: "2px solid #A9A9A9",
      },
      "&.Mui-selected": {
        // borderLeft: "0.25rem solid #00527d",
        paddingLeft: "0.75rem",
        backgroundColor:

          location.pathname === "/exco/client/impact-updates" &&
            clientApprovedData?.length > 0 &&
            !clientApprovedData[0]?.ratingData[0]?.isClientApproved &&
            clientApprovedData[0]?.ratingData[0]?.isMentorSubmitted
            ? "#A9A9A9 !important"
            : "#696969 !important",
        color: "#fff",
      },
    },
  }));

  const handleListItemClick = (index) => {
    setSelectedIndex(index);
  };

  const roleArr = [
    {
      id: 1,
      role: "HR",
    },
    {
      id: 2,
      role: "MANAGER",
    },
    {
      id: 3,
      role: "CLIENT",
    },
    {
      id: 4,
      role: "CHR",
    },
  ];
  roleArr.length > 0 &&
    roleArr.forEach((data) => {
      roleArr.push(data.role);
    });

  let resultD =
    Object.keys(rolesApiData).length > 0 &&
      rolesApiData.getExcoCustomerUserRoles.length === 1
      ? rolesApiData.getExcoCustomerUserRoles[0].role
      : localStorage.getItem("state");

  React.useEffect(() => {
    (location.pathname === "/exco/dashboard" && setSelectedIndex(1)) ||
      (location.pathname === "/exco/leadership-profile" && setSelectedIndex(2)) ||
      (location.pathname === "/exco/focus-area" && setSelectedIndex(3)) ||
      (location.pathname === "/exco/client/impact-updates" && setSelectedIndex(4)) ||
      (location.pathname === "/exco/client/progress-summary" && setSelectedIndex(5));

    (location.pathname === "/exco/hr/dashboard" && setSelectedIndex(1)) ||
      (location.pathname === "/exco/hr/leadership-profile" && setSelectedIndex(2)) ||
      (location.pathname === "/exco/hr/focus-area" &&
        setSelectedIndex(3)) ||
      (location.pathname === "/exco/hr/impact-updates" && setSelectedIndex(4)) ||
      (location.pathname === "/exco/hr/progress-summary" && setSelectedIndex(5)) ||
      (location.pathname === "/exco/hr/private-feedback" && setSelectedIndex(6));

    (location.pathname === "/exco/manager/dashboard" && setSelectedIndex(1)) ||
      (location.pathname === "/exco/manager/leadership-profile" &&
        setSelectedIndex(2)) ||
      (location.pathname === "/exco/manager/focus-area" &&
        setSelectedIndex(3)) ||
      (location.pathname === "/exco/manager/impact-updates" && setSelectedIndex(4)) ||
      (location.pathname === "/exco/manager/progress-summary" && setSelectedIndex(5))||
      (location.pathname === "/exco/manager/private-feedback" && setSelectedIndex(6));

    (location.pathname === "/exco/chr/dashboard" && setSelectedIndex(1)) ||
      (location.pathname === "/exco/chr/leadership-profile" && setSelectedIndex(2)) ||
      (location.pathname === "/exco/chr/focus-area" &&
        setSelectedIndex(3)) ||
      (location.pathname === "/exco/chr/impact-updates" && setSelectedIndex(4)) ||
      (location.pathname === "/exco/chr/progress-summary" && setSelectedIndex(5))||
      (location.pathname === "/exco/chr/private-feedback" && setSelectedIndex(6));
  }, []);

  const classes = useStyles();
 

  const handleBackClick = () => {
    navigate(
      (resultD === "CLIENT" && "/exco/dashboard") ||
      (resultD === "HR" && "/exco/hr/dashboard") ||
      (resultD === "MANAGER" && "/exco/manager/dashboard") ||
      (resultD === "CHR" && "/exco/chr/dashboard")
    );
  };

  useEffect(() => {
    if (
      (location.pathname === "/exco/dashboard") ||
      (location.pathname === "/exco/leadership-profile") ||
      (location.pathname === "/exco/focus-area") ||
      (location.pathname === "/exco/client/impact-updates") ||
      (location.pathname === "/exco/client/progress-summary") 
    
    ) {
      dispatch(getToogleData());
    }
    else {
      dispatch(getToggleUserData(clientName?.id))
    }
  }, [])

  useEffect(() => {
    if (resultD === "CLIENT") {
      if (location.pathname === "/exco/focus-area" &&
        clientToggleData?.length > 0 &&
        !clientToggleData[0]?.focusAreasTab) {
        navigate("/exco/dashboard");
      }
      if (location.pathname === "/exco/client/impact-updates" &&
        clientToggleData?.length > 0 &&
        !clientToggleData[0]?.impactUpdatesTab) {
        navigate("/exco/dashboard");
      }
      if (location.pathname === "/exco/client/progress-summary" &&
        clientToggleData?.length > 0 &&
        !clientToggleData[0]?.progressSummaryTab) {
        navigate("/exco/dashboard");
      }


    }
    if (resultD === "HR") {
      if (location.pathname === "/exco/hr/focus-area" &&
        toggleUserData?.length > 0 &&
        !toggleUserData[0]?.focusAreasTab) {
        navigate("/exco/hr/engagement-details");
      }

      if (location.pathname === "/exco/hr/progress-summary" &&
        toggleUserData?.length > 0 &&
        !toggleUserData[0]?.progressSummaryTab) {
        navigate("/exco/hr/engagement-details");
      }

    }
    if (resultD === "MANAGER") {
      if (location.pathname === "/exco/manager/focus-area" &&
        toggleUserData?.length > 0 &&
        !toggleUserData[0]?.focusAreasTab) {
        navigate("/exco/manager/engagement-details");
      }

      if (location.pathname === "/exco/manager/progress-summary" &&
        toggleUserData?.length > 0 &&
        !toggleUserData[0]?.progressSummaryTab) {
        navigate("/exco/manager/engagement-details");
      }

    }
    if (resultD === "CHR") {
      if (location.pathname === "/exco/chr/focus-area" &&
        toggleUserData?.length > 0 &&
        !toggleUserData[0]?.focusAreasTab) {
        navigate("/exco/chr/engagement-detail");
      }

      if (location.pathname === "/exco/chr/progress-summary" &&
        toggleUserData?.length > 0 &&
        !toggleUserData[0]?.progressSummaryTab) {
        navigate("/exco/chr/engagement-detail");
      }


    }


  }, [clientToggleData, toggleUserData])









 

  const CommonInfoCheck = () => {
    return (
      <>
        {
          (clientApprovedData?.length > 0 &&
            !clientApprovedData[0]?.ratingData[0]?.isClientApproved &&
            clientApprovedData[0]?.ratingData[0]?.isMentorSubmitted && (
              <MuiTooltip title="Review and approve pending impact updates">
                <ListItemIcon
                  sx={{ pl: "2rem" }}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <ErrorIcon
                    sx={{ cursor: "pointer" }}
                    fontSize="medium"
                    color="red"
                  />
                </ListItemIcon>
              </MuiTooltip>
            ))}
      </>
    );
  };
    useEffect(() => {
      dispatch(getClientIsApprpved())
    }, []);

  return (
    <>
      <div>
        <Grid container justifyContent="flex-end">
          <Grid sx={{ mt: 4 }}>
            {!props?.isClient &&
              !props?.showDetails &&
              location.pathname !== "/exco/chr/nomination" ? (
              <Stack sx={{ mb: 1 }}>
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleBackClick}
                >
                  Back to All Engagements
                </Button>
              </Stack>
            ) : (
              !props?.isClient &&
              location.pathname !== "/exco/chr/nomination" && (
                <Stack sx={{ mb: 1 }}>
                  <Button
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleBackClick}
                  >
                    Back to All Engagements
                  </Button>
                </Stack>
              )
            )}
          </Grid>
        </Grid>

        {resultD === "CLIENT" && roleArr.includes(resultD) && (
          <Grid container spacing={1}>
            <Grid item xs>
              <Link to="/exco/dashboard" className={classes.MuiLinks}>
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                    whiteSpace: "nowrap",
                  }}
                  className={classes.listButton}
                  onClick={() => setSelectedIndex(1)}
                  selected={selectedIndex === 1}
                >
                  Engagement Home
                </ListItemButton>
              </Link>
            </Grid>

            <Grid item xs>
              <Link to="/exco/leadership-profile" className={classes.MuiLinks}>
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                    whiteSpace: "nowrap",
                  }}
                  className={classes.listButton}
                  onClick={() => setSelectedIndex(2)}
                  selected={selectedIndex === 2}
                >
                  Leadership Profile
                </ListItemButton>
              </Link>
            </Grid>



            {clientToggleData?.length > 0 &&
              clientToggleData[0]?.focusAreasTab
              && (<Grid item xs>
                <Link to="/exco/focus-area" className={classes.MuiLinks}>
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                      whiteSpace: "nowrap",
                    }}
                    className={classes.listButton}
                    onClick={() => {
                      handleListItemClick(3);
                    }}
                    selected={selectedIndex === 3}
                  >
                    Focus Areas
                  </ListItemButton>
                </Link>
              </Grid>)}



            {clientToggleData?.length > 0 &&
              clientToggleData[0]?.impactUpdatesTab
              && (<Grid item xs>
                <Link to="/exco/client/impact-updates" className={classes.MuiLinks}>
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                      whiteSpace: "nowrap",
                    }}
                    className={classes.listButton}
                    onClick={(event) => {
                      handleListItemClick(4);
                    }}
                    selected={selectedIndex === 4}
                  >
                    Impact Updates
                    <CommonInfoCheck />
                  </ListItemButton>
                </Link>
              </Grid>)}

            {clientToggleData?.length > 0 &&
              clientToggleData[0]?.progressSummaryTab
              && (<Grid item xs>
                <Link to="/exco/client/progress-summary" className={classes.MuiLinks}>
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                      whiteSpace: "nowrap",
                    }}
                    className={classes.listButton}
                    onClick={() => {
                      handleListItemClick(5);
                    }}
                    selected={selectedIndex === 5}
                  >
                    Impact Summary
                    {/* <CommonInfoCheck /> */}
                  </ListItemButton>
                </Link>
              </Grid>)}
          </Grid>
        )}
        {resultD === "HR" && roleArr.includes(resultD) && (
          <Grid container spacing={1}>
            <Grid item xs>
              <Link
                to="/exco/hr/engagement-details"
                className={classes.MuiLinks}
              >
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                  }}
                  className={classes.listButton}
                  onClick={() => setSelectedIndex(1)}
                  selected={selectedIndex === 1}
                >
                  Engagement Details
                  {/* <Item>Leadership profile</Item> */}
                </ListItemButton>
              </Link>
            </Grid>

            <Grid item xs>
              <Link to="/exco/hr/leadership-profile" className={classes.MuiLinks}>
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                  }}
                  className={classes.listButton}
                  onClick={() => setSelectedIndex(2)}
                  selected={selectedIndex === 2}
                >
                  Leadership Profile
                </ListItemButton>
              </Link>
            </Grid>

            {toggleUserData?.length > 0 &&
              toggleUserData[0]?.focusAreasTab
              && (<Grid item xs>
                <Link to="/exco/hr/focus-area" className={classes.MuiLinks}>
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                    }}
                    className={classes.listButton}
                    onClick={() => {
                      handleListItemClick(3);
                    }}
                    selected={selectedIndex === 3}
                  >
                    Focus Areas
                  </ListItemButton>
                </Link>
              </Grid>)}


            {/* <Grid item xs>
              <Link to="/exco/hr/ratings" className={classes.MuiLinks}>
                <ListItemButton
                  className={classes.listButton}
                  onClick={(event) => {
                    handleListItemClick(4);
                  }}
                  selected={selectedIndex === 4}
                >
                  Impact Updates
                </ListItemButton>
              </Link>
            </Grid> */}

            {toggleUserData?.length > 0 &&
              toggleUserData[0]?.progressSummaryTab
              && (<Grid item xs>
                <Link to="/exco/hr/progress-summary" className={classes.MuiLinks}>
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                    }}
                    className={classes.listButton}
                    onClick={() => {
                      handleListItemClick(5);
                    }}
                    selected={selectedIndex === 5}
                  >
                    Impact Summary
                  </ListItemButton>
                </Link>
              </Grid>)}

              <Grid item xs>
              <Link to="/exco/hr/private-feedback" className={classes.MuiLinks}>
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                  }}
                  className={classes.listButton}
                  onClick={() => {
                    handleListItemClick(6);
                  }}
                  selected={selectedIndex === 6}
                >
                 Private Feedback
                </ListItemButton>
              </Link>
            </Grid>

          </Grid>
        )}

        {resultD === "CHR" && roleArr.includes(resultD) && (
          <Grid container spacing={1}>
            <Grid item xs>
              <Link
                to="/exco/chr/engagement-details"
                className={classes.MuiLinks}
              >
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                  }}
                  className={classes.listButton}
                  onClick={() => setSelectedIndex(1)}
                  selected={selectedIndex === 1}
                >
                  Engagement Details
                  {/* <Item>Leadership profile</Item> */}
                </ListItemButton>
              </Link>
            </Grid>

            <Grid item xs>
              <Link to="/exco/chr/leadership-profile" className={classes.MuiLinks}>
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                  }}
                  className={classes.listButton}
                  onClick={() => setSelectedIndex(2)}
                  selected={selectedIndex === 2}
                >
                  Leadership Profile
                </ListItemButton>
              </Link>
            </Grid>

            {toggleUserData?.length > 0 &&
              toggleUserData[0]?.focusAreasTab
              && (<Grid item xs>
                <Link to="/exco/chr/focus-area" className={classes.MuiLinks}>
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                    }}
                    className={classes.listButton}
                    onClick={() => {
                      handleListItemClick(3);
                    }}
                    selected={selectedIndex === 3}
                  >
                    Focus Areas
                  </ListItemButton>
                </Link>
              </Grid>)}

            {/* <Grid item xs>
              <Link to="/exco/hr/ratings" className={classes.MuiLinks}>
                <ListItemButton
                  className={classes.listButton}
                  onClick={(event) => {
                    handleListItemClick(4);
                  }}
                  selected={selectedIndex === 4}
                >
                  Impact Updates
                </ListItemButton>
              </Link>
            </Grid> */}

            {toggleUserData?.length > 0 &&
              toggleUserData[0]?.progressSummaryTab
              && (<Grid item xs>
                <Link to="/exco/chr/progress-summary" className={classes.MuiLinks}>
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                    }}
                    className={classes.listButton}
                    onClick={() => {
                      handleListItemClick(5);
                    }}
                    selected={selectedIndex === 5}
                  >
                    Impact Summary
                  </ListItemButton>
                </Link>
              </Grid>)}
              <Grid item xs>
              <Link to="/exco/chr/private-feedback" className={classes.MuiLinks}>
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                  }}
                  className={classes.listButton}
                  onClick={() => {
                    handleListItemClick(6);
                  }}
                  selected={selectedIndex === 6}
                >
                 Private Feedback
                </ListItemButton>
              </Link>
            </Grid>
          </Grid>
        )}

        {resultD === "MANAGER" && roleArr.includes(resultD) && (
          <Grid container spacing={1}>
            <Grid item xs>
              <Link
                to="/exco/manager/engagement-details"
                className={classes.MuiLinks}
              >
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                  }}
                  className={classes.listButton}
                  onClick={() => setSelectedIndex(1)}
                  selected={selectedIndex === 1}
                >
                  Engagement Details
                  {/* <Item>Leadership profile</Item> */}
                </ListItemButton>
              </Link>
            </Grid>

            <Grid item xs>
              <Link to="/exco/manager/leadership-profile" className={classes.MuiLinks}>
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                  }}
                  className={classes.listButton}
                  onClick={() => setSelectedIndex(2)}
                  selected={selectedIndex === 2}
                >
                  Leadership Profile
                </ListItemButton>
              </Link>
            </Grid>

            {toggleUserData?.length > 0 &&
              toggleUserData[0]?.focusAreasTab
              && (<Grid item xs>
                <Link
                  to="/exco/manager/focus-area"
                  className={classes.MuiLinks}
                >
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                    }}
                    className={classes.listButton}
                    onClick={() => {
                      handleListItemClick(3);
                    }}
                    selected={selectedIndex === 3}
                  >
                    Focus Areas
                  </ListItemButton>
                </Link>
              </Grid>)}

            {/* <Grid item xs>
              <Link to="/exco/manager/ratings" className={classes.MuiLinks}>
                <ListItemButton
                  className={classes.listButton}
                  onClick={(event) => {
                    handleListItemClick(4);
                  }}
                  selected={selectedIndex === 4}
                >
                  Impact Updates
                </ListItemButton>
              </Link>
            </Grid> */}

            {toggleUserData?.length > 0 &&
              toggleUserData[0]?.progressSummaryTab
              && (<Grid item xs>
                <Link to="/exco/manager/progress-summary" className={classes.MuiLinks}>
                  <ListItemButton
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "0.4rem",
                    }}
                    className={classes.listButton}
                    onClick={() => {
                      handleListItemClick(5);
                    }}
                    selected={selectedIndex === 5}
                  >
                    Impact Summary
                  </ListItemButton>
                </Link>
              </Grid>)}
              <Grid item xs>
              <Link to="/exco/manager/private-feedback" className={classes.MuiLinks}>
                <ListItemButton
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "0.4rem",
                  }}
                  className={classes.listButton}
                  onClick={() => {
                    handleListItemClick(6);
                  }}
                  selected={selectedIndex === 6}
                >
                 Private Feedback
                </ListItemButton>
              </Link>
            </Grid>

          </Grid>
        )}
      </div>
    </>
  );
};
