import { Box, Card, CardContent, CardHeader, IconButton } from "@mui/material";
import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Cancel, Delete } from "@mui/icons-material";
import { MuiTooltip } from "..";
import { PopUpModel } from "..";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { ASSESSMENT_BASE_URL } from "../../utils/config";
// -------------------------------------------------------------------------------------
const CommonCrudPopup = ({
  bigScreen,
  mediumScreen,
  smallScreen,
  largeScreen,
  customWidth,
  heading,
  heading1,
  close,
  children,
  veryBigScreen,
  aiScreen,
  isDownload,
  TARGET_URL,
  targetId,
  isUpload,
  upload,
  iconType, // Add new prop
  ...props
}) => {
  // console.log(targetId)
  return (
    <PopUpModel open={true}>
      <Card
        style={{
          width: largeScreen
            ? "76.5vw"
            : mediumScreen
              ? "60vw"
              : smallScreen
                ? "45vw"
                : customWidth || "76.5vw",
          marginTop: "16.5vh",
          marginLeft: smallScreen ? "32vw" : mediumScreen ? "27vw" : "15vw",
          // margin: "auto",
          position: "relative",

          display: "flex",
          flexDirection: "column",
          maxHeight: "80vh", // Ensures the popup doesn't overflow the viewport
        }}
        className="res_update_uc"
      >
        {(heading || heading1 || close) && (
          <CardHeader
            title={
              (heading || heading1) && (
                <h5
                  style={{

                    backgroundColor: heading1 ? "#b9d6ee" : aiScreen ? "cadetblue" : "transparent",
                    color: heading1 ? "black" : aiScreen ? "white" : "inherit",
                    // padding: "0.5rem",
                    width: "100%",
                    textAlign: "center",
                  }}
                  className="bold header-font-size"
                >
                  {heading || heading1}
                </h5>
              )
            }
            action={
              <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                {isUpload && (
                  <MuiTooltip title="Upload Documents">
                    <IconButton onClick={upload}>
                      {iconType === "folder" ? (
                        <DriveFolderUploadIcon color="primary" />
                      ) : (
                        <AddCircleIcon color="primary" />
                      )}
                    </IconButton>
                  </MuiTooltip>
                )}
                {isDownload && (
                  <MuiTooltip title="Download">
                    <a href={`${TARGET_URL}/${targetId}`} target="_blank" rel="noopener noreferrer">
                      <IconButton>
                        <CloudDownloadIcon />
                      </IconButton>
                    </a>
                  </MuiTooltip>
                )}
                {close && (
                  <MuiTooltip title="Close">
                    <IconButton aria-label="cancel" onClick={close}>
                      <CancelIcon color="error" />
                    </IconButton>
                  </MuiTooltip>
                )}
              </div>
            }
            sx={{ paddingBottom: "0px!important" }}
          />
        )}

        {/* CardContent now expands and scrolls fully */}
        <CardContent style={{ maxHeight: "85vh", marginTop: "-2rem", overflow: "auto", }}>
          {children}
        </CardContent>
      </Card>
    </PopUpModel>

  );
};

export default CommonCrudPopup;
